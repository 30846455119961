

const Company = () => {
    return (
        <div style={{textAlign: 'center', minWidth: '50%', maxWidth: '70%', fontSize: '22px', flex: 1}}>
            <div style={{marginBottom: '25px', fontSize: "32px"}}>Our Story</div>
            <img style={{maxWidth: '230px', maxHeight: '245px', float: 'left', borderRadius: '10%', marginRight: '20px'}} alt="founder" src={require('../images/founder.jpg')} />
            <div style={{textAlign: 'left'}}>
            <p>
            Our founder, Ariel Colon, has dedicated her entire career to Trust and Safety, 
            addressing safety issues at Snapchat, Patreon, and Zoom. With experience in content 
            moderation and software engineering, Ariel has consistently bridged gaps 
            between operational and technical teams to support more effective programs.
            </p>
            <p>
            She was inspired to create Kolla, named after the Greek word for glue, while researching 
            challenges that hinder the efficiency of Trust and Safety teams. Recognizing the persistent 
            challenges of managing high volumes, frequent policy and workflow updates, and the growing array of tools, Ariel 
            resolved to leverage AI technology to provide a dedicated teammate to every Trust and Safety professional. 
            </p>
            <p>
            The Teammate is designed to streamline operations, enabling professionals to focus less on 
            navigating operational complexities and more on their core safety responsibilities. We are most excited about 
            building AI-powered actions for Trust and Safety teams. 
            We will seamlessly integrate your core tools into the Teammate so your operators may access knowledge and 
            take action from anywhere throughout their workflow.
            </p>
            </div>
        </div>
    )
}

export default Company;