import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const Product = () => {
    return (
        <div style={{textAlign: 'center', minWidth: '50%', maxWidth: '70%', fontSize: '22px', flex: 1}}>
            <div style={{fontSize: '32px', marginBottom: '20px'}}>Introducing <b>Teammate</b>*</div> 
            <ChatBubbleIcon sx={{ fontSize: 100 }} color='black' />
            <div style={{fontSize: '28px', margin: '20px'}}>Your AI-powered Trust And Safety Assistant</div>
        {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '20px'}}> */}
            <p style={{fontSize: '24px'}}>Empower your Trust and Safety professionals with the ultimate ally: Teammate,
            a Chrome extension designed to simplify content moderation tasks. Our tool 
            features a chat interface and advanced AI technology to support Trust and Safety professionals in their 
            daily workflows.
            </p>
        
        <div style={{textAlign: 'left'}}>
            <p style={{ fontSize: 30 }}><b>What we offer:</b></p>
            <p><CheckBoxIcon sx={{ fontSize: 24, marginRight: '10px' }} /><b>Seamless Integration:</b> One-click download that will enable Teammate throughout your browsing experience.</p>
            <p><CheckBoxIcon sx={{ fontSize: 24, marginRight: '10px' }} /><b>Efficiency Boost:</b> Utilize AI to make informed decisions quickly and automate moderation tasks with built-in user confirmation.</p>
            <p><CheckBoxIcon sx={{ fontSize: 24, marginRight: '10px' }} /><b>On-Demand Help:</b> Round-the-clock assistance to access helpful insights and recommendations instantly within your browser.</p>
            <p><CheckBoxIcon sx={{ fontSize: 24, marginRight: '10px' }} /><b>Business Intelligence:</b> Gain valuable insights into moderator usage patterns to optimize policies and workflows for scalability.</p>
        </div>
        <div style={{marginBottom: '40px', marginTop: '70px', textAlign: 'left'}}>
        *🚀 Now in Beta: We're excited to announce that Teammate is now available in beta! 
        Join us as we refine our product and shape the future of content moderation.
        </div>
        </div>
    )
}

export default Product;