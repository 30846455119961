const Footer = () => {
    return (
    <div style={{
        position: 'relative',
        // display: 'flex',
        // flexDirection: 'row',
        left: 10,
        bottom: 0, 
        padding: '15px',
        fontSize: '13px', 
        width: '100%'}}>
        <p>
            <a style={{textDecoration: "none", color: 'black'}} href='/privacy'>Privacy Policy</a>
        </p>
        <p>
            <a style={{textDecoration: "none", color: 'black'}} href='/tos'>Terms and Conditions</a> 
        </p>
        {/* <p>
        Copyright © 2024 Kolla LLC, All rights reserved.
        </p> */}
    </div>
    )
}

export default Footer