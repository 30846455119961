const Terms = () => {
    return (
        <div style={{textAlign: 'left', minWidth: '50%', maxWidth: '70%', fontSize: '22px', flex: 1}}>
<h2>
	Terms and Conditions
</h2>
<p>These terms and conditions ("Terms," "Agreement") govern your use of https://thekolla.com 
    ("the Website") and Teammate ("the Service") operated by Kolla LLC ("Kolla")</p>
    <p>By accessing the Website ad using the Service, you agree to be bound by these Terms. If you disagree with 
        any part of these terms, you may not access the Website or the Service.</p>
<h3>
	1. Intellectual Property
</h3>

<p>
All content on the Website and the Service, including text, graphics, logos, images, and software, is the property 
of the Company and is protected by intellectual property laws. You may not use, reproduce, or 
distribute any content from the Website without prior written consent from the Company.
</p>

<h3>
	2. User Conduct
</h3>

<p>You agree to use the Website and the Service only for lawful purposes and in a manner consistent with all applicable laws and regulations. 
    You may not engage in any activity that disrupts or interferes with the functioning of the Website, the Service or its users.</p>

<h3>
	3. Limitation of Liability
</h3>

<p>
The Company shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or relating to 
your use of the Website or the Service, including but not limited to damages for loss of profits, data, or other intangible losses.
</p>

<h3>
	4. Indemnification
</h3>

<p>
You agree to indemnify and hold harmless the Company and its affiliates, officers, directors, employees, and agents from any claims, 
liabilities, damages, or expenses arising out of your use of the Website or violation of these Terms.
</p>
			
<h3>
	5. Modifications
</h3>

<p>
The Company reserves the right to modify or replace these Terms at any time without prior notice. 
Your continued use of the Website after any changes constitutes acceptance of the modified Terms.
</p>

<h3>
	6. Governing Law
</h3>

<p>
    These Terms shall be governed by and construed in accordance with the laws of California, without regard to its conflict of law provisions.
</p>

<h3>
	7. Contact Information
</h3>

<p>
If you have any questions about these Terms, please contact us at hello@thekolla.com.
</p>
</div>
    )
}

export default Terms