const Privacy = () => {
    return (
        <div style={{textAlign: 'left', minWidth: '50%', maxWidth: '70%', fontSize: '22px', flex: 1}}>
        <h2>
            Privacy Policy
        </h2>

        <p>This Privacy Policy describes how Kolla LLC ("Kolla", "we," "us," or "our") collects, uses, and shares personal 
            information when you use our website (https://thekolla.com) and related services (collectively, the "Service").</p>

        <h2>Information We Collect</h2>
        <ul>
            <li><strong>Contact Information:</strong> When you use our contact form, we may collect your name, email address, and any other information you provide in your message for communication purposes.</li>
            <li><strong>Google SSO:</strong> If you choose to sign in using Google Single Sign-On (SSO), we may collect personal information provided by Google, such as your name and email address, in accordance with Google's privacy policy.</li>
            <li><strong>Third-Party APIs:</strong> We may use third-party APIs to enhance the functionality of our Service. These APIs may collect personal information as described in their respective privacy policies.</li>
            <li><strong>Usage Data:</strong> We may collect information about how you use the Service, including your interactions with the website and features, for internal purposes and to improve the Service. This information may be shared with our customers if necessary.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We may use the personal information we collect for the following purposes:</p>
        <ul>
            <li>To provide and maintain the Service;</li>
            <li>To communicate with you, including responding to inquiries and providing customer support;</li>
            <li>To analyze and improve the Service's performance and usability;</li>
            <li>To comply with legal obligations; and</li>
            <li>For any other purpose disclosed to you at the time of collection or with your consent.</li>
        </ul>

        <h2>Information Sharing</h2>
        <p>We may share your personal information with third-party service providers, business partners, or affiliates who assist us in providing the Service or operating our business. We may also disclose your information in response to legal requests or to protect our rights, property, or safety.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at hello@thekolla.com.</p>
        </div>
    )
}

export default Privacy