import './index.css';
import './App.css';
import DrawerAppBar from './components/Navigation';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Product from './components/Product';
import ContactUs from './components/ContactUs';
import Company from './components/Company';
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Terms from './components/Terms';


const THEME = createTheme({
  typography: {
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`
  },
});


function App() {
  return (
    <div className='App'>
      <MuiThemeProvider theme={THEME}>
      <DrawerAppBar />
      <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/product" element={<Product />}/>
          <Route path="/contact" element={<ContactUs />}/>
          <Route path="/company" element={<Company />}/>
          <Route path="/privacy" element={<Privacy />}/>
          <Route path="/tos" element={<Terms />}/>
      </Routes>
      <Footer />
      </MuiThemeProvider>
    </div>
  );
}

export default App;
