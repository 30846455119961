const Home = () => {
    return (
        <div style={{
            display: 'flex', 
            flexDirection: 'column', 
            position: 'relative',
            flex: 1,
            justifyContent: 'center', 
            textAlign: 'center', 
            maxWidth: '60%'}}>
            <div style={{fontSize: '48px'}}>
                Empower Your Trust and Safety Workflow: AI Integration for Smarter Operations
            </div>
            <div style={{
                marginTop: '100px',
                textAlign: "center", 
                maxWidth: '60%', 
                fontSize: '20px', 
                marginLeft: 'auto', 
                marginRight: 'auto'
                }}>
                Brought to you by Trust and Safety and Engineering leaders with
            experience in complex B2B and B2C SaaS companies
            <div style={{flexDirection: 'row', justifyContent: 'space-between'}} >
                <img alt="zoom" style={{width: '100px', padding: '15px'}} src={require('../images/zoom-logo.png')} />
                <img alt="patreon" style={{width: '100px'}} src={require('../images/patreon-logo-transparent.png')} />
                <img alt="goodrx" style={{width: '100px'}} src={require('../images/goodrx-logo.png')} />
                </div>
            </div>
        </div>
    )
}

export default Home;