import React, { useState } from 'react';
import {Button, TextField, Typography} from '@mui/material';
import httpClient from "../utils/httpClient";
import {titleCase} from '../utils/helpers';
import CircularProgress from '@mui/material/CircularProgress';


const ContactUs = () => {
    const arr = ['name', 'email', 'hear']
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const[ message, setMessage ] = useState('')
    const[ demoValues, setDemoValues ] = useState({
        name: '',
        email: '',
        hear: ''
    })
    const handleOnChange = (e) => {
        e.preventDefault()
        if (demoValues[e.target.id].length < 200 || e.target.value.length < demoValues[e.target.id].length) {
            setDemoValues({...demoValues, [e.target.id]: e.target.value})
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(demoValues['email'])) {
            setErrorMessage('')
            setError(false)
        }
    }
    const onSubmit = async (event) => {
        setIsLoading(true)
        event.preventDefault()
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(demoValues['email'])) {
            setErrorMessage('please provide a valid email address')
            setError(true); // Set error state to true if email is invalid
            return; // Stop further execution
        }
        const params = Object.entries(demoValues).reduce((acc, [k, v]) => v ? {...acc, [k]:v} : acc , {})
        httpClient.post(`${process.env.REACT_APP_BASE_URL}/contact`, params,
        {
            'Content-Type': 'application/json'
        })
        .then((response) => {
            if (response.status === 200) {
                setDemoValues({
                    name: '',
                    email: '',
                    hear: ''
                })
                setMessage("Message has been sent. Will will be in touch soon!")
                setIsLoading(false)
            } else {
                throw new Error();
            }
        }).catch((error) => {
            setMessage("Something went wrong.")
            setIsLoading(false)
        })
    }
    const helperTexts = {
        name: '',
        hear: `${demoValues['hear'].length}/200 character limit`,
        email: error && errorMessage
    }
    return (
        <div style={{textAlign: 'center', flex: 1}}>
        <Typography variant='h4'>Learn More</Typography>
        <form onSubmit={(e) => onSubmit(e)}>
            {arr.map((value) => {
                return (
                    <div key={value}>
                        <TextField
                            label={value !== 'hear' ? titleCase(value) : 'Your Message'}
                            variant="filled"
                            sx={{
                                width: "500px", 
                                margin: '10px',
                                label: { color: 'black'},
                            }}
                            InputLabelProps={{ style: { color: "black" } }}
                            InputProps={{ 
                                style: { color: "black", fontSize: "18px", backgroundColor: 'lightgrey'}, 
                                disableUnderline: true,
                            }}
                            FormHelperTextProps={{style: { color: "black" }}}
                            multiline={value !== 'hear' ? false : true}
                            rows={value !== 'hear' ? 1 : 4}
                            id={value}
                            name={value}
                            value={demoValues[value]}
                            error={error}
                            helperText={helperTexts[value]}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                )
            })
            }
            {message && <div style={{fontSize: "20px", margin: "4px"}}>{message}</div>}
            {isLoading ? <CircularProgress /> :
            <Button sx={{backgroundColor: '#4a6e67', fontSize: "20px", textTransform: 'None'}} variant="contained" type="submit">Send Message</Button>}
        </form>
        </div>
    )
}

export default ContactUs;